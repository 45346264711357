import axios from 'axios';
import { useEffect, useState } from 'react';

const Logowanie = ({ token }: { token: string }) => {
  const [data, setData] = useState<{ accessToken: string } | null>(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.post<{ accessToken: string }>(
          `${import.meta.env.VITE_API_URL}/auth/jwt`,
          { token },
        );
        setData(data);
      } catch (e) {
        console.error(e);
        setIsError(true);
      }
    };

    getData();
  }, []);

  return (
    <div className="my-3">
      <p>Logowanie</p>
      {data && <p>Twój token dostępu: {data.accessToken}</p>}
      {isError && <p>Wystąpił błąd podczas logowania</p>}
    </div>
  );
};

const Przyklad = ({ token }: { token: string }) => {
  const [data, setData] = useState<{ message: string } | null>(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.post<{ message: string }>(
          `${import.meta.env.VITE_API_URL}/auth/dummysecured`,
          { token },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setData(data);
      } catch (e) {
        console.error(e);
        setIsError(true);
      }
    };

    getData();
  }, []);

  return (
    <div className="my-3">
      <p>Zapytanie 1</p>
      {data && <p>Zapytanie 1: {data.message}</p>}
      {isError && <p>Wystąpił błąd podczas zapytania</p>}
    </div>
  );
};

const Przyklad2 = ({ token }: { token: string }) => {
  const [data, setData] = useState<{ message: string } | null>(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.post<{ message: string }>(
          `${import.meta.env.VITE_API_URL}/auth/dummyunsecured`,
          { token },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setData(data);
      } catch (e) {
        console.error(e);
        setIsError(true);
      }
    };

    getData();
  }, []);

  return (
    <div className="my-3">
      <p>Zapytanie 2</p>
      {data && <p>Zapytanie 2: {data.message}</p>}
      {isError && <p>Wystąpił błąd podczas zapytania</p>}
    </div>
  );
};

const PizzaList = ({ token }: { token: string }) => {
  const [data, setData] = useState<{ pizzas: any[] } | null>(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.get(
          `${import.meta.env.VITE_API_URL}/public/pizzas`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setData(data);
      } catch (e) {
        console.error(e);
        setIsError(true);
      }
    };

    getData();
  }, []);

  return (
    <div className="my-3">
      <p>Publiczna lista pizzy</p>
      {data && data.pizzas.map((pizza) => <p>{pizza.name}</p>)}
      {isError && <p>Wystąpił błąd podczas pobierania danych</p>}
    </div>
  );
};

const Zasoby = ({ token }: { token: string }) => {
  return (
    <div className="mt-4">
      <p>Zasoby</p>
      <Logowanie token={token} />
      <Przyklad token={token} />
      <Przyklad2 token={token} />
      <PizzaList token={token} />
    </div>
  );
};
export default Zasoby;

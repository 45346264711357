import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { App } from './App';
import { IngredientCategories } from './components/ingredientCategories/IngredientCategories';
import { Ingredients } from './components/ingredients/Ingredients';
import { Login } from './components/login/Login';
import { OrdersView } from './components/ordersView/OrdersView';
import { Pizza } from './components/pizza/Pizza';
import { PizzaView } from './components/pizzaView/PizzaView';
import { ProductCategory } from './components/productCategory/ProductCategory';
import { ProductView } from './components/productView/ProductView';
import { Products } from './components/products/Products';
import { Delivery } from './components/settings/Delivery';
import { IsPizzeriaOpened } from './components/settings/IsPizzeriaOpen';
import { Mailing } from './components/settings/Mailing';
import { Settings } from './components/settings/Settings';
import { SupPrices } from './components/settings/SupPrices';
import { WaitingTime } from './components/settings/WaitingTime';
import { WorkingHours } from './components/settings/WorkingHours';
import { Sizes } from './components/sizes/Sizes';
import Dane from './components/daneView/Dane';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<App />}>
        <Route path="zamowienia" element={<OrdersView />} />
        <Route path="pizza" element={<PizzaView />}>
          <Route path="pizza" element={<Pizza />} />
          <Route path="skladniki" element={<Ingredients />} />
          <Route path="rozmiary" element={<Sizes />} />
          <Route
            path="kategorie-składników"
            element={<IngredientCategories />}
          />
        </Route>
        <Route path="produkty" element={<ProductView />}>
          <Route path="produkty" element={<Products />} />
          <Route path="kategorie-produktów" element={<ProductCategory />} />
        </Route>
        <Route path="ustawienia" element={<Settings />}>
          <Route path="mailing" element={<Mailing />} />
          <Route path="dostawa" element={<Delivery />} />
          <Route path="godziny-pracy" element={<WorkingHours />} />
          <Route path="przyjmowanie-zamowien" element={<IsPizzeriaOpened />} />
          <Route path="sup" element={<SupPrices />} />
          <Route path="oczekiwanie" element={<WaitingTime />} />
        </Route>
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/dane" element={<Dane />} />
    </>,
  ),
);

export default router;

// export const router = createBrowserRouter([
//   {
//     path: '/*',
//     element: <App />,
//     children: [
//       {
//         path: 'zamowienia',
//         element: <OrdersView />,
//       },
//       {
//         path: 'pizza',
//         element: <PizzaView />,
//         children: [
//           {
//             path: 'pizza',
//             element: <Pizza />,
//           },
//           {
//             path: 'skladniki',
//             element: <Ingredients />,
//           },
//           {
//             path: 'rozmiary',
//             element: <Sizes />,
//           },
//           {
//             path: 'kategorie-składników',
//             element: <IngredientCategories />,
//           },
//         ],
//       },
//       {
//         path: 'produkty',
//         element: <ProductView />,
//         children: [
//           {
//             path: 'produkty',
//             element: <Products />,
//           },
//           {
//             path: 'kategorie-produktów',
//             element: <ProductCategory />,
//           },
//         ],
//       },
//       {
//         path: 'ustawienia',
//         element: <Settings />,
//         children: [
//           {
//             path: 'mailing',
//             element: <Mailing />,
//           },
//           {
//             path: 'dostawa',
//             element: <Delivery />,
//           },
//           {
//             path: 'godziny-pracy',
//             element: <WorkingHours />,
//           },
//           {
//             path: 'przyjmowanie-zamowien',
//             element: <IsPizzeriaOpened />,
//           },
//           {
//             path: 'sup',
//             element: <SupPrices />,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     path: '/login',
//     element: <Login />,
//   },
// ]);

import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Zasoby from './Zasoby';

const Dane = () => {
  const [token, setToken] = useState('');
  const [isReady, setIsReady] = useState(false);
  const [isError, setIsError] = useState(false);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const init = async () => {
      try {
        const token = await getAccessTokenSilently();
        setToken(token);

        console.log(token);
      } catch (e) {
        console.error(e);
        setIsError(true);
      }
      setIsReady(true);
    };

    if (!isLoading && isAuthenticated) {
      init();
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  return (
    <div className="p-6">
      {!isReady && !isError && <div>Ładowanie...</div>}
      {token && <p>Twój token dostępu: {token}</p>}
      {isError && <div>Wystąpił błąd podczas pobierania tokenu</div>}
      {token && (
        <Link to={'/'}>
          <button className="p-2 bg-gray-200">Kontynuuj</button>
        </Link>
      )}
      {token && <Zasoby token={token} />}
    </div>
  );
};
export default Dane;
